require('dayjs/locale/sl');
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/plugin/weekOfYear';
import localeSl from '@angular/common/locales/sl';

import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
// import { TooltipModule } from 'ng2-tooltip-directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarEditorComponent, CalendarMonthComponent, CalendarYearComponent } from './calendar';
import { DietPickerComponent, GroupEditorComponent, GroupFormComponent } from './groups';
import { CacheRouteReuseStrategy, DietCombinationPipe, DietNamePipe, GroupTypePipe, MealNamePipe } from './shared';
import { LoginComponent } from './user/login/login.component';
import { ProductEditorComponent } from './foods/product-editor/product-editor.component';
import { ProductFormComponent } from './foods/product-form/product-form.component';
import {
  AgeGroupPipe,
  AllergenNamePipe,
  ComponentBarsComponent,
  ComponentNamePipe,
  ComponentPieComponent,
  DietCodePipe,
  DietDetailsPipe,
  EnergyBarComponent,
  ErrorComponent,
  FoodListComponent,
  FoodSubgroupPipe,
  GastroNamePipe,
  HeaderComponent,
  LoadingIndicatorComponent,
  MainWithAsideComponent,
  MealSizePipe,
  NumberComponent,
  PyramideCompactComponent,
  PyramideVerticalComponent,
  QualitySchemePipe,
  SeasonNamePipe,
  SegmentedBarComponent,
  SelectArrayComponent
} from '@app/shared';
import { ProductListComponent } from './foods/product-list/product-list.component';
import { RecipeEditorComponent } from './foods/recipe-editor/recipe-editor.component';
import { RecipeFormComponent } from './foods/recipe-form/recipe-form.component';
import { CheckboxArrayComponent } from './shared/checkbox-array/checkbox-array.component';
import { CookingMethodNamePipe } from './shared/cooking-method-name.pipe';
import { TitleComponent } from './shared/title/title.component';
import { EmptyFieldDirective } from './shared/empty-field.directive';
import { SearchComponent } from './shared/search/search.component';
import { SelectButtonComponent } from './shared/select-button/select-button.component';
import { PieChartComponent } from './shared/pie-chart/pie-chart.component';
import { MenuMonthComponent } from './menu/menu-month/menu-month.component';
import { GroupExceptionsComponent } from './groups/group-exceptions/group-exceptions.component';
import { GroupListComponent } from './groups/group-list/group-list.component';
import { MenuDayComponent } from './menu/menu-day/menu-day.component';
import { MenuFoodSearchComponent } from './menu/menu-food-search/menu-food-search.component';
import { MealSizePickerComponent } from './menu/meal-size-picker/meal-size-picker.component';
import { MenuYearComponent } from './menu/menu-year/menu-year.component';
import { MenuWeekComponent } from './menu/menu-week/menu-week.component';
import { MenuSidebarComponent } from './menu/menu-sidebar/menu-sidebar.component';
import { FormClassDirective } from './shared/form-class.directive';
import { DunfordPipe } from './shared/dunford.pipe';
import { DunfordComponent } from './foods/dunford/dunford.component';
import { CategoryNamePipe } from './shared/category-name.pipe';
import { ForeignSearchComponent } from './foods/foreign-search/foreign-search.component';
import { ShoppingListComponent } from './prep/shopping-list/shopping-list.component';
import { CornerButtonsComponent } from './shared/corner-buttons/corner-buttons.component';
import { InstructionsComponent } from './shared/instructions/instructions.component';
import { ToggleHelpComponent } from './shared/toggle-help/toggle-help.component';
import { SentryErrorHandler } from './sentry.handler';
import { CountComponent } from './shared/count/count.component';
import { RadioArrayComponent } from './shared/radio-array/radio-array.component';
import { OrganisationListComponent } from './admin/organisation-list/organisation-list.component';
import { ActivateComponent } from './user/activate/activate.component';
import { LostPasswordComponent } from './user/lost-password/lost-password.component';
import { CalendarGenerateComponent } from './calendar/calendar-generate/calendar-generate.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { DatePickerComponent } from './shared/date-picker/date-picker.component';
import { MenuSizePickerComponent } from './menu/menu-size-picker/menu-size-picker.component';
import { RouteReuseStrategy } from '@angular/router';
import { ValueBarComponent } from './shared/value-bar/value-bar.component';
import { EnergyPipe } from './energy.pipe';
import { PrepReplaceComponent } from './prep/prep-replace/prep-replace.component';
import { MenuCopyComponent } from './menu/menu-copy/menu-copy.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { MenuFavoriteComponent } from './menu/menu-favorite/menu-favorite.component';
import { CheckboxComponent } from './shared/checkbox/checkbox.component';
import { EcatalogComponent } from './foods/ecatalog/ecatalog.component';
import { EcatalogNamePipe } from './shared/ecatalog-type.pipe';
import { SubmitButtonComponent } from './shared/submit-button/submit-button.component';
import { ImportProductComponent } from './foods/import-product/import-product.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { TooltipComponent } from './shared/tooltip/tooltip-component/tooltip.component';
import { TooltipDirective } from './shared/tooltip/tooltip-directive.directive';
import { FooterComponent } from './shared/footer/footer.component';
import { WelcomeComponent } from './home/welcome/welcome.component';
import { InfoDetailsComponent } from './home/info-details/info-details.component';
import { ContactComponent } from './home/contact/contact.component';
import { PrivacyComponent } from './home/privacy/privacy.component';
import { CalendarYearsComponent } from './calendar/calendar-years/calendar-years.component';
import { ProfileComponent } from './user/profile/profile.component';
import { GeneralPopupComponent } from './shared/general-popup/general-popup.component';

registerLocaleData(localeSl);

@NgModule({
  declarations: [
    AppComponent,
    CalendarEditorComponent,
    CalendarMonthComponent,
    CalendarYearComponent,
    GroupEditorComponent,
    GroupFormComponent,
    DietPickerComponent,
    DietCombinationPipe,
    DietNamePipe,
    GroupTypePipe,
    MealNamePipe,
    LoginComponent,
    ProductEditorComponent,
    ProductFormComponent,
    ComponentNamePipe,
    QualitySchemePipe,
    FoodSubgroupPipe,
    ProductListComponent,
    RecipeEditorComponent,
    RecipeFormComponent,
    CheckboxArrayComponent,
    CookingMethodNamePipe,
    SeasonNamePipe,
    DietCodePipe,
    HeaderComponent,
    MainWithAsideComponent,
    TitleComponent,
    EmptyFieldDirective,
    SearchComponent,
    SelectButtonComponent,
    PieChartComponent,
    MenuMonthComponent,
    GroupExceptionsComponent,
    GroupListComponent,
    MenuDayComponent,
    MenuFoodSearchComponent,
    MealSizePickerComponent,
    FoodListComponent,
    LoadingIndicatorComponent,
    MealSizePipe,
    ComponentPieComponent,
    MenuYearComponent,
    EnergyBarComponent,
    ComponentBarsComponent,
    PyramideCompactComponent,
    SegmentedBarComponent,
    PyramideVerticalComponent,
    MenuWeekComponent,
    MenuSidebarComponent,
    AgeGroupPipe,
    FormClassDirective,
    DunfordPipe,
    AllergenNamePipe,
    DunfordComponent,
    GastroNamePipe,
    SelectArrayComponent,
    CategoryNamePipe,
    ForeignSearchComponent,
    ShoppingListComponent,
    CornerButtonsComponent,
    InstructionsComponent,
    ToggleHelpComponent,
    NumberComponent,
    CountComponent,
    RadioArrayComponent,
    OrganisationListComponent,
    ActivateComponent,
    LostPasswordComponent,
    CalendarGenerateComponent,
    ErrorComponent,
    DatePickerComponent,
    MenuSizePickerComponent,
    DietDetailsPipe,
    ValueBarComponent,
    EnergyPipe,
    PrepReplaceComponent,
    MenuCopyComponent,
    UserLayoutComponent,
    MenuFavoriteComponent,
    CheckboxComponent,
    EcatalogComponent,
    EcatalogNamePipe,
    SubmitButtonComponent,
    ImportProductComponent,
    AppLayoutComponent,
    TooltipComponent,
    TooltipDirective,
    FooterComponent,
    WelcomeComponent,
    InfoDetailsComponent,
    ContactComponent,
    PrivacyComponent,
    CalendarYearsComponent,
    ProfileComponent,
    GeneralPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    Ng2FlatpickrModule,
    // TooltipModule.forRoot({
    //   'tooltip-class': 'default-tooltip'
    // })
  ],
  providers: [
    DecimalPipe,
    DietNamePipe,
    CookingMethodNamePipe,
    SeasonNamePipe,
    MealNamePipe,
    AllergenNamePipe,
    DunfordPipe,
    GastroNamePipe,
    CategoryNamePipe,
    QualitySchemePipe,
    MealSizePipe,
    DietCombinationPipe,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    ComponentNamePipe,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    GroupTypePipe,
    {
      provide: LOCALE_ID,
      useValue: "sl_SI"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    dayjs.extend(localeData);
    dayjs.extend(isoWeek);
    dayjs.locale('sl');
    dayjs.extend((option, c, d) => {
      (c.prototype as any).week = function () {
        const i = d(this);

        const utc = new Date(Date.UTC(i.year(), i.month(), i.date()));
        const dayNum = utc.getUTCDay() || 7;
        utc.setUTCDate(utc.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(utc.getUTCFullYear(), 0, 1));
        return Math.ceil(((((utc as any) - (yearStart as any)) / 86400000) + 1) / 7);
      };
    });
  }
}
