<label *ngIf="value0">
  <input type="radio" checked>{{ title0 }}
  <button type="button" (click)="onSelect(0, null)"></button>
</label>
<label *ngIf="value1" class="second">
  <input type="radio" checked>{{ title1 }}
  <button type="button" (click)="onSelect(1, null)"></button>
</label>
<div class="scrollable" #scrollable>
  <app-radio-array #levelZero [options]="level0" [class.hidden]="value0"></app-radio-array>
  <app-radio-array #levelOne [options]="level1" [class.hidden]="value1" class="second"></app-radio-array>
  <app-radio-array #levelTwo [options]="level2" class="third"></app-radio-array>
</div>
