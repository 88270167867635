import { Component, GroupType, MealSize } from './api.abstract.service';

export const DEFAULT_COMPONENTS = [
  Component.Energy,
  Component.Fat,
  Component.SaturatedFattyAcids,
  Component.TotalCarbohydrates,
  Component.TotalSugars,
  Component.Protein,
  Component.Sodium
];

export const COMPONENT_DEFAULTS = new Map([
  [Component.Energy,              { unit: 'kcal', factor: 1 / 4184, label: 'Energijska vrednost' }],
  [Component.TotalCarbohydrates,  { unit: 'g',    factor: 1,        label: 'Ogljikovi hidrati'  }],
  [Component.TotalSugars,         { unit: 'g',    factor: 1,        indent: true, label: 'od tega sladkorji' }],
  [Component.Fat,                 { unit: 'g',    factor: 1,        label: 'Maščobe'  }],
  [Component.SaturatedFattyAcids, { unit: 'g',    factor: 1,        indent: true, label: 'od tega nasičene maščobe' }],
  [Component.Protein,             { unit: 'g',    factor: 1,        label: 'Beljakovine'  }],
  [Component.Sodium,              { unit: 'g',   factor: 1,     label: 'Sol', title: '' }],
]);

export const GROUP_TYPE_TO_SIZES = new Map([
  [GroupType.Kindergarten, [MealSize.KindergartenSmall, MealSize.KindergartenLarge]],
  [GroupType.ElementarySchool, [MealSize.ElementarySmall, MealSize.ElementaryMedium, MealSize.ElementaryLarge]],
  [GroupType.MiddleSchool, [MealSize.MiddleSmall, MealSize.MiddleLarge]],
  [GroupType.Adult, [MealSize.AdultSmall, MealSize.AdultLarge]]
]);

export const SIZE_TO_GROUP_TYPE = new Map([
  [MealSize.KindergartenSmall, GroupType.Kindergarten],
  [MealSize.KindergartenLarge, GroupType.Kindergarten],
  [MealSize.ElementarySmall, GroupType.ElementarySchool],
  [MealSize.ElementaryMedium, GroupType.ElementarySchool],
  [MealSize.ElementaryLarge, GroupType.ElementarySchool],
  [MealSize.MiddleSmall, GroupType.MiddleSchool],
  [MealSize.MiddleLarge, GroupType.MiddleSchool],
  [MealSize.AdultSmall, GroupType.Adult],
  [MealSize.AdultLarge, GroupType.Adult],
]);
