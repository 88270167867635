import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import {
  AllGastroRecipe,
  AllQualitySchemes,
  AllSeasons,
  ApiService,
  BasicFood,
  CheckboxArrayOptionSimple,
  CreateRecipeRequest,
  DietDetails,
  DietNamePipe,
  ErrorComponent,
  FindFoodRequest,
  FoodKind,
  MealSize,
  Recipe,
  SeasonNamePipe,
  SubscriptionSink
} from '@app/shared';
import { checkboxTitleComparator } from '@app/shared/checkbox-array/checkbox-array.component';
import { GastroNamePipe } from '@app/shared/gastro-name.pipe';
import { QualitySchemePipe } from '@app/shared/quality-scheme.pipe';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { omit } from 'lodash-es';
import { SubmitButtonComponent } from '@app/shared/submit-button/submit-button.component';
import { GeneralPopupComponent } from '@app/shared/general-popup/general-popup.component';

@Component({
  selector: 'app-recipe-editor',
  templateUrl: './recipe-editor.component.html',
  styleUrls: ['./recipe-editor.component.scss']
})
export class RecipeEditorComponent implements OnInit, OnDestroy {

  @ViewChild(ErrorComponent, { static: true })
  error: ErrorComponent;

  @ViewChild('submit', {static: false})
  submitButton: SubmitButtonComponent;

  @ViewChild(GeneralPopupComponent, {static: true})
  generalPopup: GeneralPopupComponent;

  recipe: Omit<Recipe, 'id'> & { id?: number } = null;

  yearId: number;

  isNew = false;

  results: BasicFood[];

  searchForm: FormGroup;

  anyFilter = false;

  sizes: MealSize[];

  allGastro: CheckboxArrayOptionSimple[];

  allQualitySchemes: CheckboxArrayOptionSimple[];

  allDiets: CheckboxArrayOptionSimple[];

  allSeasons: CheckboxArrayOptionSimple[];

  usedDiets: DietDetails[];

  protected offset = 0;

  protected limit = 30;

  protected count: number;

  protected subscription = new SubscriptionSink();

  protected isDirty = false;

  protected returnUrl: UrlTree;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected api: ApiService,
    protected fb: FormBuilder,
    gastroName: GastroNamePipe,
    qualitySchemeName: QualitySchemePipe,
    dietName: DietNamePipe,
    seasonName: SeasonNamePipe,
  ) {
    this.allGastro = AllGastroRecipe.map(gastro => ({
      title: gastroName.transform(gastro),
      value: gastro
    })).sort(checkboxTitleComparator);
    this.allQualitySchemes = AllQualitySchemes.map(scheme => ({
      title: qualitySchemeName.transform(scheme),
      value: scheme,
    })).sort(checkboxTitleComparator);
    this.allSeasons = AllSeasons.map(season => ({
      title: seasonName.transform(season),
      value: season,
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      query: [],
      gastro: [],
      qualityScheme: [],
      diet: [],
      season: [],
    });

    this.subscription.sink = this.searchForm
      .valueChanges
      .pipe(
        tap((v: any) => {
          this.anyFilter = v.gastro !== null || v.qualityScheme !== null || v.diet !== null || v.season !== null;
          this.offset = 0;
          this.results = [];
        }),
        switchMap(
          () => this.fetch()
        )
      )
      .subscribe();

    this.subscription.sink = this.route
      .data
      .pipe(
        tap(({ yearId, isNew, food, yearSizes, diets }) => {
          this.yearId = yearId;
          this.isNew = isNew;
          this.recipe = food;
          this.usedDiets = diets;
          this.sizes = (yearSizes || []).sort();
          this.allDiets = diets.map(diet => ({
            title: diet.name,
            value: diet.id,
          })).sort(checkboxTitleComparator);
        }),
        switchMap(() => {
          if (this.results?.length > 0) {
            return EMPTY;
          }
          return this.fetch();
        }),
        switchMap(() =>
          this.api.getHelpVisible('recipe')
        ),
        debounceTime(250),
        switchMap(visible => {
          if (visible || this.isNew || this.recipe) {
            return EMPTY;
          }
          if (this.results.length > 0) {
            return this.router.navigate(['recipe', this.results[0].id]);
          }

          return this.router.navigate(['recipe', 'new']);
        })
      )
      .subscribe();

    this.subscription.sink = this.api
      .food$
      .pipe(
        switchMap(
          () => this.fetch()
        )
      )
      .subscribe();

    this.error.reset();

    this.route.queryParams.subscribe(params => {
      if (params['return'] && this.router.parseUrl(params['return'])) {
        this.returnUrl = this.router.parseUrl(params['return']);
      }
    });
  }

  save(request: Omit<CreateRecipeRequest, 'yearId'>) {
    let observable: Observable<Recipe>;
    if (this.isNew || !this.recipe.id) {
      observable = this.api.foodCreateRecipe({
        yearId: this.yearId,
        ...omit(request, 'removedPhoto'),
      });
    } else {
      observable = this.api.foodUpdateRecipe({
        id: this.recipe.id,
        yearId: this.recipe.yearId,
        ...request,
      });
    }

    this.error.reset();
    this.subscription.sink = observable
      .pipe(this.error.operator({button: this.submitButton}))
      .subscribe(rv => {
        this.setDirty(false);
        // If rv.values is empty, it means that some foods in the recipe do not have a food group assigned
        if (rv.values.length === 0) {
          this.generalPopup.show("Manjkajo skupine živil", "Nekatera živila v sestavinah nimajo določene skupine živil, zato ni mogoče izračunati hranilnih vrednosti recepta.");
        }
        if (this.returnUrl) {
          this.returnUrl.queryParams.food_id = rv.id;
          this.router.navigateByUrl(this.returnUrl);
          return;
        }
        if (this.recipe?.id === rv.id) {
          this.recipe = rv;
        } else {
          this.router.navigate(['/recipe', rv.id]);
        }
      });
  }

  selected(food: BasicFood) {
    this.router.navigate(['/recipe', food.id]);
  }

  resetSearchForm() {
    this.searchForm.patchValue({
      gastro: null,
      qualityScheme: null,
      diet: null,
      season: null,
    });
  }

  clearFilter(name: string) {
    this.searchForm.get(name).setValue(null, {});
  }

  delete(recipe: {name: string, id?: number}) {
    if (!recipe.id) {
      return;
    }

    if (!confirm(`Ste prepričani da želite izbrisati recept ${recipe.name}?`)) {
      return;
    }

    this.error.reset();
    this.subscription.sink = this.api.foodDeleteFood({
      yearId: this.yearId,
      foodId: recipe.id
    })
      .pipe(this.error.operator())
      .subscribe(reason => {
        if (reason) {
          alert(reason);
          return;
        }
        this.setDirty(false);
        return this.router.navigate(['/recipe']);
      });
  }

  copy() {
    this.recipe = {
      ...omit(this.recipe, 'id', 'photos', 'removedPhoto'),
      name: `KOPIJA - ${this.recipe.name}`,
      photos: [],
    };
  }

  loadRecipes() {
    if (this.results.length >= this.count) {
      return;
    }

    this.offset += this.limit;
    this.fetch().subscribe();
  }

  protected fetch() {
    const { query, qualityScheme, gastro, diet, season } = this.searchForm.value;
    let request: FindFoodRequest = {
      yearId: this.yearId,
      kind: FoodKind.Recipe,
      offset: this.offset,
      limit: this.limit,
    };

    if (query || qualityScheme || gastro || diet || season) {
      request = {
        ...request,
        query,
        gastro,
        qualityScheme,
        dietId: diet,
        season,
      };
    }

    return this.api
      .foodFindFood(request)
      .pipe(
        tap(response => {
          if (this.offset > 0) {
            response.foods.forEach(f => {
              if (this.results.findIndex(e => e.id === f.id) === -1) {
                this.results.push(f);
              }
            });
          } else {
            this.results = response.foods;
          }
          this.count = response.count;
        })
      );
  }

  setDirty(d: boolean) {
    this.isDirty = d;
    this.submitButton?.setStatus(d ? 'dirty' : 'initial');
  }

  checkDirty() {
    if  (!this.isDirty) {
      return true;
    }

    if  (confirm('Spremembe niso shranjene.\nKliknite prekliči, če želite nadaljevati z urejanjem.\nS klikom na potrdi bodo spremembe izgubljene.')) {
      this.isDirty = false;
      return true;
    }

    return false;
  }
}
