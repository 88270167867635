import { AfterViewChecked, Component, ElementRef, forwardRef, OnChanges, OnInit, ViewChild, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CheckboxArrayOption,
  checkboxTitleComparator, ECatalog,
  eCatalogCategoryNames,
  eCatalogSubcategoryNames,
  eCatalogSubcategoryToCategory,
  ApiService
} from '@app/shared';
import { checkboxIsGroup } from '@app/shared/checkbox-array/checkbox-array.component';
import { RadioArrayComponent } from '@app/shared/radio-array/radio-array.component';
import { Observer } from 'rxjs';

@Component({
  selector: 'app-ecatalog',
  templateUrl: './ecatalog.component.html',
  styleUrls: ['./ecatalog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EcatalogComponent),
      multi: true
    }
  ]
})
export class EcatalogComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewChecked {

  protected changeListeners = [];

  level0: CheckboxArrayOption[] = [];

  level1: CheckboxArrayOption[] = [];

  level2: CheckboxArrayOption[] = [];

  value0: number | null = null;

  title0: string | null = null;

  value1: number | null = null;

  title1: string | null = null;

  value2: number | null = null;

  title2: string | null = null;

  @Input()
  values?: any;

  @ViewChild('levelZero', {static: true})
  control0: RadioArrayComponent;

  @ViewChild('levelOne', {static: true})
  control1: RadioArrayComponent;

  @ViewChild('levelTwo', {static: true})
  control2: RadioArrayComponent;

  @ViewChild('scrollable', {static: false})
  scrollableEl: ElementRef<HTMLDivElement>;

  protected notifyChangeListeners(value: number) {
    this.changeListeners.forEach(listener => listener(value));
  }

  constructor(protected api: ApiService) {
  }

  getFoodGroupTitleById(id: number, options: CheckboxArrayOption[]): string | null {
    for (const option of options) {
        if (!checkboxIsGroup(option) && option.value === id) {
            return option.title;
        }
    }
    return null;
  }

  protected setCode(ind: number, code: number) {
    if (ind === 0) {
      this.value0 = code;
      this.title0 = this.getFoodGroupTitleById(code, this.level0);

      this.level1 = [];
      this.value1 = null;
      this.title1 = null;

      this.level2 = [];
      this.value2 = null;
      this.title2 = null;

      if (code === null) {
        return;
      }

      const observer: Observer<any> = {
        next: (children) => {
          children.foodGroups.forEach(foodGroup => {
            this.level1.push({value: foodGroup.id, title: foodGroup.FGNM});
          });
        },
        error: (error) => {
          console.error('Error fetching food group children', error);
        },
        complete: () => {
          // console.log('API call completed');
        }
      };

      this.api.foodGetFoodGroupChildren({ parentId: code }).subscribe(observer);

      this.level1.sort(checkboxTitleComparator);

    } else if (ind === 1) {
      this.value1 = code;
      this.title1 = this.getFoodGroupTitleById(code, this.level1);

      // The second level cannot be chosen if the third level is already chosen
      // this can only happen on initial load so we skip the api call to avoid duplicates
      if (code !== null && this.value2 !== null) {
        return;
      }

      this.level2 = [];
      this.value2 = null;
      this.title2 = null;

      if (code === null) {
        return;
      }

      const observer: Observer<any> = {
        next: (children) => {
          children.foodGroups.forEach(foodGroup => {
            this.level2.push({value: foodGroup.id, title: foodGroup.FGNM});
          });
        },
        error: (error) => {
          console.error('Error fetching food group children', error);
        },
        complete: () => {
          // console.log('API call completed');
        }
      };

      this.api.foodGetFoodGroupChildren({ parentId: code }).subscribe(observer);

      this.level2.sort(checkboxTitleComparator);
    } else if (ind === 2) {
      this.value2 = code;
    }
  }

  private writeControlValues() {
    this.control0.writeValue(this.value0);
    this.control1.writeValue(this.value1);
    this.control2.writeValue(this.value2);
  }

  onSelect(ind: number, code: number) {
    this.setCode(ind, code);
    this.writeControlValues();
    this.notifyChangeListeners(this.value2);
  }

  ngOnInit() {
    this.level0 = [];
    this.values.foodGroups.foodGroupsL1.forEach(foodGroup => {
      this.level0.push({value: foodGroup.id, title: foodGroup.FGNM})
    }
    );
    this.level0.sort(checkboxTitleComparator);

    this.level1 = [];
    this.control0.registerOnChange(this.onSelect.bind(this, 0));
    this.control1.registerOnChange(this.onSelect.bind(this, 1));
    this.control2.registerOnChange(this.onSelect.bind(this, 2));
  }

  ngOnChanges() {
    // compoletely reset this component
    this.level1 = [];
    this.level2 = [];

    this.control0.writeValue(null);
    this.control1.writeValue(null);
    this.control2.writeValue(null);

    this.value0 = null;
    this.title0 = null;

    this.value1 = null;
    this.title1 = null;

    this.value2 = null;
    this.title2 = null;

    // Set the initial values needed
    if (this.values.foodGroups.foodGroupsL2 !== null) {
      this.values.foodGroups.foodGroupsL1.forEach(foodGroup => {
        if (foodGroup.selected) {
          this.setCode(0, foodGroup.id);
          this.title0 = foodGroup.FGNM;
          this.value0 = foodGroup.id;
        }
      });

      // First we need to handle the third level before the second level
      // so we can easily determine if the second level selection is on initial load
      this.values.foodGroups.foodGroupsL3.forEach(foodGroup => {
        this.level2.push({value: foodGroup.id, title: foodGroup.FGNM})
        if (foodGroup.selected) {
          this.value2 = foodGroup.id;
        }
      });
      this.level2.sort(checkboxTitleComparator);

      this.values.foodGroups.foodGroupsL2.forEach(foodGroup => {
        this.level1.push({value: foodGroup.id, title: foodGroup.FGNM})
        if (foodGroup.selected) {
          this.setCode(1, foodGroup.id);
          this.title1 = foodGroup.FGNM;
          this.value1 = foodGroup.id;
        }
      }
      );
      this.level1.sort(checkboxTitleComparator);

    } else {
      this.setCode(0, null);
    }

    this.writeControlValues();
  }

  writeValue(value: ECatalog) {
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  onDefault(value: number) {
    if (value) {
      this.notifyChangeListeners(value);
    }
  }

  ngAfterViewChecked() {
    if (!this.scrollableEl) {
      return;
    }

    const checked: HTMLInputElement = this.scrollableEl.nativeElement.querySelector('app-radio-array:not(.hidden) input:checked');
    if (!checked) {
      return;
    }
    this.scrollableEl.nativeElement.scrollTop = checked.parentElement.offsetTop - ~~(this.scrollableEl.nativeElement.offsetHeight / 2 - 10);
  }
}
