import { Component, Input, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxArrayOption, checkboxIsGroup } from '../checkbox-array/checkbox-array.component';

@Component({
  selector: 'app-select-array',
  templateUrl: './select-array.component.html',
  styleUrls: ['./select-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectArrayComponent),
      multi: true
    }
  ]
})
export class SelectArrayComponent implements ControlValueAccessor {

  @ViewChild('select', {static: true})
  selectEl: ElementRef<HTMLSelectElement>;

  @Input()
  emptyTitle: string;

  @Input()
  options: CheckboxArrayOption[] = [];

  @Input()
  selectedDark = false;

  @Input()
  isDisabled = false

  isGroup = checkboxIsGroup;

  value: any;

  protected changeListeners = [];

  constructor() { }

  protected notifyChangeListeners(value) {
    this.changeListeners.forEach(listener => listener(value));
  }

  indent(n: number | undefined) {
    if (!n) {
      return '';
    }
    return [...Array(n)].map(() => '\u00A0').join('');
  }

  changed($event) {
    const target = $event.target;
    const selectedIndex = target.selectedIndex;
    let selected;
    let index = 0;

    const found = this.options.some(option => {
      if (this.isGroup(option)) {
        return option.children.some(({ value }) => {
          index++;
          if (selectedIndex === index) {
            selected = value;
            return true;
          }
        });
      }

      index++;
      if (selectedIndex === index) {
        selected = option.value;
        return true;
      }

      return false;
    });

    if (found) {
      this.value = selected;
      this.notifyChangeListeners(selected);
    } else {
      this.value = null;
      this.notifyChangeListeners(null);
    }
  }

  writeValue(newValue: any): void {
    this.value = newValue;
    if (!newValue) {
      this.selectEl.nativeElement.selectedIndex = 0;
    }
  }
  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
  }

}
