import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-popup',
  templateUrl: './general-popup.component.html',
  styleUrls: ['./general-popup.component.scss']
})
export class GeneralPopupComponent implements OnInit {
  visible: boolean;
  title: string;
  body: string;

  constructor() { }

  ngOnInit(): void {
  }

  show(title: string, body: string) {
    this.title = title;
    this.body = body;
    this.visible = true;
  }

  closePopup() {
    this.visible = false;
  }
}
