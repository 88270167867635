<app-title icon="recipe_thin" text="Recepti">
  <app-toggle-help>
    Recepti vplivajo na kakovost vaših jedilnikov, zato morajo biti sestavljeni iz kakovostnih sestavin.
    V tem delu e-orodje omogoča vnos receptur za jedi, ki jih pripravljate v vaši kuhinji.
    V recepte lahko vključujete le živila, ki so na seznamu upravičenih živil vašega zavoda (javnega naročil).
    Vsak kuhar pripravlja jedi po svojih recepturah, zato je pomembno, da vsak vzgojno-izobraževalni zavod vodi lastno bazo receptov.
    Vnosa receptov se lotite postopno.
    Po določenem času boste prišli do lastne kuharske knjige.
    E-orodje omogoča, da bodo vaše recepture opredeljene glede na primernost določene medicinsko indicirane diete, sezono, vsebnost alergenov ter kulinarične skupine.
  </app-toggle-help>
</app-title>

<app-main-with-aside>
  <ng-container aside>
    <button class="btn-add-horizontal-large" [routerLink]="['/recipe', 'new']">
      <span>
        Dodaj nov<br>
        recept
      </span>
    </button>
    <form [formGroup]="searchForm">
      <div class="search-box">
        <input formControlName="query" placeholder="Išči recept">
      </div>
      <button class="search-filter--all" type="button" (click)="resetSearchForm()" [class.search-filter--all-inactive]="anyFilter">Vse</button>
      <div class="form-group">
        <app-select-array [options]="allGastro" formControlName="gastro" emptyTitle="Kulinarična skupine" [selectedDark]="true">
        </app-select-array>
      </div>
      <div class="form-group">
        <app-select-array [options]="allSeasons" formControlName="season" emptyTitle="Sezone" [selectedDark]="true"></app-select-array>
      </div>
      <div class="form-group">
        <app-select-array [options]="allDiets" formControlName="diet" emptyTitle="Diete" [selectedDark]="true">
        </app-select-array>
      </div>
    </form>
    <app-food-list [foods]="results" [selected]="recipe?.id" (clicked)="selected($event)" [canDelete]="true" (delete)="delete($event)" (bottom)="loadRecipes()"></app-food-list>
  </ng-container>
  <ng-container main>
    <app-error></app-error>
    <app-recipe-form *ngIf="isNew || recipe" [yearId]="yearId" [recipe]="recipe" (save)="save($event)" [sizes]="sizes" [customDiets]="usedDiets" (dirty)="setDirty($event)">
      <button *ngIf="!isNew && recipe && recipe.id" class="btn-copy" (click)="copy()" type="button">Kopiraj</button>
      <button *ngIf="recipe?.id" class="btn-cancel" type="button" (click)="delete(recipe)">Izbriši</button>
      <app-submit-button #submit></app-submit-button>
    </app-recipe-form>
    <div id="aside-marker"></div>
  </ng-container>
</app-main-with-aside>
<app-general-popup></app-general-popup>