<div class="container" [class.value-selected]="value" [class.dark-selected]="selectedDark">
  <select (change)="changed($event)" #select [disabled]="isDisabled">
    <option [selected]="!value" value="">{{ emptyTitle }}</option>
    <ng-container *ngFor="let option of options">
      <option [disabled]="option.disabled" *ngIf="!isGroup(option)" [selected]="value == option.value">
        {{ indent(option.indent) + option.title }}
      </option>
      <optgroup *ngIf="isGroup(option)" [label]="option.title">
        <ng-container *ngFor="let child of option.children">
          <option [disabled]="child.disabled" [selected]="value == child.value">
            {{ indent(child.indent) + child.title }}
          </option>
        </ng-container>
      </optgroup>
    </ng-container>
  </select>
</div>
